// extracted by mini-css-extract-plugin
export var imgContainer = "index-module--imgContainer--26Dw6";
export var imgTextContainer = "index-module--imgTextContainer--qCvHc";
export var imgText = "index-module--imgText--2hwD8";
export var bylineContainer = "index-module--bylineContainer--1hzNH";
export var bylineContent = "index-module--bylineContent--3fMPE";
export var leftByline = "index-module--leftByline--YaJMi";
export var rightByline = "index-module--rightByline--3irxg";
export var splitContainer = "index-module--splitContainer--2CXdv";
export var splitContainerContent = "index-module--splitContainerContent--YxCvR";
export var textContainer = "index-module--textContainer--1y9tl";
export var imageContainer = "index-module--imageContainer--3NBNn";