import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

import Header from "../components/Header/header"
import * as styles from "../styles/index.module.css"
import Footer from "../components/Footer/footer"

const IndexPage = () => (
    <React.Fragment>
        <Helmet title="Silver Oaks" />

        <Header />

        <div>
            <StaticImage
                src="../images/home-bg.jpg"
                alt="Timber forest"
                placeholder="blurred"
                layout="fullWidth"
                className={styles.imgContainer}
            />
            <div className={styles.imgTextContainer}>
                <h1 className={styles.imgText}>Radiata Pine Timber</h1>
            </div>
        </div>

        <div className={styles.bylineContainer}>
            <div className={styles.bylineContent}>
                <div className={styles.leftByline}>
                    New Zealand is acknowledge as one of the world leading
                    suppliers of high-quality pine timber. It has also the most
                    advanced technology for clear-wood production.
                </div>
                <div className={styles.rightByline}>
                    All 1.8 million hectares of New Zealand’s plantation are
                    renewable and nearly half are Forest Stewardship Council
                    (FSC) Certified. New Zealand’s pine forests are highly
                    productive and expanding-a rarity on the global scene.
                </div>
            </div>
        </div>

        <div
            className={styles.splitContainer}
            style={{ paddingTop: "6.6vmin" }}
        >
            <div className={styles.splitContainerContent}>
                <div
                    className={styles.textContainer}
                    style={{ marginRight: 30 }}
                >
                    <h1>Radiata Pine</h1>
                    <p>
                        New Zealand-grown Pinus radiata is a “super softwood” of
                        the 21st century – one of the most attractive and
                        versatile industrial wood species available in global
                        markets. It performs consistently well across a wider
                        range of commercial applications when compared with
                        almost any other species.
                        <br /> <br />
                        Pine outperforms major competing timbers from North
                        America, Europe and the tropics. In a Scion (New
                        Zealand) comparison of machining and mechanical
                        properties with 13 similar North American species, New
                        Zealand pine finished a clear winner, with an overall
                        score of 70% against an average score of 59%.
                    </p>
                </div>
                <div
                    className={styles.imageContainer}
                    style={{ marginLeft: 30 }}
                >
                    <StaticImage
                        src="../images/logs.jpg"
                        alt="Timber logs"
                    />
                </div>
            </div>
        </div>

        <div
            className={styles.splitContainer}
            style={{ paddingBottom: "6.6vmin", paddingTop: "100px" }}
        >
            <div className={styles.splitContainerContent}>
                <div
                    className={styles.imageContainer}
                    style={{ marginRight: 30 }}
                >
                    <StaticImage
                        src="../images/pallet.jpg"
                        alt="Timber pallet"
                    />
                </div>
                <div
                    className={styles.textContainer}
                    style={{ marginLeft: 30 }}
                >
                    <h1>Timber Applications</h1>
                    <p>
                        New Zealand pine timber has a variety of applications,
                        including as construction and furniture material, as
                        well as several industrial uses (e.g., pallets, wooden
                        crates, and cable drums)
                    </p>
                    <Link to="/products">Learn More</Link>
                </div>
            </div>
        </div>

        <Footer />
    </React.Fragment>
)

export default IndexPage
